
import { AsyncView } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '@/components/Forms/Form.vue';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';

@Component({
  components: {
    Form,
  },
})
export default class TenanacyAgreement extends Mixins(AsyncView) {
  private formData: FormConstructorData | null = null;
}
